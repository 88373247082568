import jquery from "jquery";
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import "./scss/index.scss";
import "@popperjs/core";
import "bootstrap";

import "@fancyapps/fancybox";

import simpleParallax from "simple-parallax-js";

import "slick-carousel";
import hcSticky from "hc-sticky";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";


//#region bootstrap navbar + hamburger + body scroll lock
var navbarMain = document.getElementById("navbarMain");
navbarMain.addEventListener("show.bs.collapse", function () {
  $(".navbar-toggler .hamburger").addClass("is-active");
  let scrollableNav = document.querySelector("#navbarMain");
  disableBodyScroll(scrollableNav);
});
navbarMain.addEventListener("hidden.bs.collapse", function () {
  $(".navbar-toggler .hamburger").removeClass("is-active");
  let scrollableNav = document.querySelector("#navbarMain");
  enableBodyScroll(scrollableNav);
});
//#endregion

//#region stickybar
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".stickybar", {
    stickTo: "body",
    stickyClass: "stickybar--sticked",
  });
});
//#endregion

//#region Home Slider
$(function () {
  $(".slider").slick({
    prevArrow: '.slider__arrow--prev',
    nextArrow: '.slider__arrow--next',
  });
});
//#endregion

//#region parallax - Home
var image = document.getElementsByClassName("parallax__img");
new simpleParallax(image, {
  delay: 0.1,
  transition: "cubic-bezier(.14,.99,.73,.36)",
  scale: 1.3,
});
//#endregion

//#region ModalEvents
$(document).ready(function () {
  setTimeout(function () {
    $("#modalEvents").modal('show');
  }, 2000);
});

//#endregion